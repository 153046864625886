// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bv_kC";
export var caseStudyBackground__lineColor = "bv_kz";
export var caseStudyHead = "bv_kt";
export var caseStudyHead__imageWrapper = "bv_kv";
export var caseStudyProjectsSection = "bv_kD";
export var caseStudyQuote__bgLight = "bv_kK";
export var caseStudyQuote__bgRing = "bv_kx";
export var caseStudyVideoReview = "bv_k9";
export var caseStudyVideoReview__bgRing = "bv_lb";
export var caseStudyVideo__ring = "bv_kN";
export var caseStudy__bgDark = "bv_ks";
export var caseStudy__bgLight = "bv_kr";
export var caseStudy__bgLightReverse = "bv_k8";